@font-face {
  font-family: 'Lighters';
  src: local('Lighters'), url('./assets/fonts/Lighters-Regular.ttf') format('TTF');
}

@font-face {
    font-family: 'Lighters';
    font-weight: 600;
    src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 400;
  src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('TTF');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 200;
  src: local('Lighters'), url('./assets/fonts/Lighters-ExtraLight.ttf') format('TTF');
}

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 500ms ease-in, -webkit-filter 500ms ease-in;
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}


.diagonalBox {
  background: purple;
  color: white;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding: 30vh 0%;
}

.diagonalBox2 {
  background: white;
  clip-path: polygon(0% calc(100% - 220px), 100% 100%, 100% 0%, 0% 0%);
  padding: 90px 0% 250px 0%;
}

.diagonalContacto {
  margin-top: 300px;
  background: black;
  
  padding: 250px 0% 0px 0%;
}

