@font-face {
  font-family: 'Lighters';
  src: local('Lighters'), url('./assets/fonts/Lighters-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lighters';
    font-weight: 600;
    src: local('Lighters'), url('./assets/fonts/Lighters-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 300;
  src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lighters';
  font-weight: 200;
  src: local('Lighters'), url('./assets/fonts/Lighters-Light.ttf') format('truetype');
}

body {
  margin: 0;
  font-family:"Lighters";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fontSignika {
  font-family: "Lighters" !important;
}

.fontSanchez {
  font-family: "Sanchez" !important;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f4f4f4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f4f4f4;
}


.MuiFormLabel-root.Mui-focused{
  color: black !important;
}


.MuiInput-underline:after {
  border-bottom: 2px solid black !important;
}